import React from 'react';
import utils from '../utils'
import StatusList from './StatusList';
import { Bluetooth, Update, BatteryAlert, BatteryFull, SystemUpdate, Link, LinkOff, Check, Cancel, BluetoothDisabled } from "@material-ui/icons";

//Threshold Values
const BatteryValueThreshold = 2700;
const vistaRssiThreshold = 100;

export default React.memo(function VistaStatus({ vistaStatus, vistaPingStatus }) {

    if (vistaStatus.loading) {
        return (<div>Loading....</div>);
    }
    if (vistaStatus.error) {
        return (<div>Verify Serial#...</div>);
    }
    if (!vistaStatus.data) {
        return (<div>Enter device serial# to view current status</div>)
    }

    let { FirmwareVersion, LastPing, LatestHealthRecordEventTime, ImageEventBatteryValue, TimeZoneAbbreviation, AssetSerialNumber } = vistaStatus.data;
    let { AdvertisementData, Rssi, EventTime } = vistaPingStatus.data || {};

    const lastPingGood = LastPing && utils.date_Diff_In_Mins(new Date(), utils.DateTimeWithoutTimezone(LastPing)) < 10;
    const lastPing = LastPing ? utils.DateTimeWithoutTimezone(LastPing) + ' ' + (TimeZoneAbbreviation || "") : '';
    const batteryV = utils.getVoltage(ImageEventBatteryValue);
    const listItems = [
        {
            primary: lastPingGood ? "Found recently" : "Please Sync again",
            secondary: lastPing,
            goodIcon: <Check />,
            badIcon: <Cancel />,
            isGood: lastPingGood,
            action: 'Please Sync again to get the latest ping'
        },
        {
            primary: (Rssi && Math.abs(Rssi) < vistaRssiThreshold) ? "Good Signal" : "Signal issue - Need hub closer",
            secondary: Rssi ? Math.abs(Rssi) : "",
            goodIcon: <Bluetooth />,
            badIcon: <BluetoothDisabled />,
            isGood: Math.abs(Rssi) < vistaRssiThreshold,
            action: 'Try to move vista to a location closer to Hub'
        },
        {
            primary: AdvertisementData || "Sync again after install",
            secondary: EventTime ? utils.DateTimeWithoutTimezone(EventTime) : "",
            goodIcon: <Update />,
            badIcon: <Update />,
            isGood: EventTime && utils.date_Diff_In_Mins(new Date(), utils.DateTimeWithoutTimezone(EventTime)) < 10,
            action: 'Sync the vista to make advertisement data'
        },
        {
            primary: AssetSerialNumber ? ("Already installed on Asset# " + AssetSerialNumber) : "New",
            secondary: "",
            goodIcon: <Link />,
            badIcon: <LinkOff />,
            isGood: !!AssetSerialNumber,
            action: 'Selected Asset will link after complete installation'
        },
        {
            primary: ImageEventBatteryValue < BatteryValueThreshold ? "Needs replacement/ depassivation" : "Good",
            secondary: batteryV,
            goodIcon: <BatteryFull />,
            badIcon: <BatteryAlert />,
            isGood: ImageEventBatteryValue > BatteryValueThreshold,
            action: 'Need to replace the battery'
        },
        {
            primary: FirmwareVersion,
            secondary: "",
            goodIcon: <SystemUpdate />,
            badIcon: <SystemUpdate />,
            isGood: true
        }
    ];

    return <StatusList listItems={listItems} />
});
