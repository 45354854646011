import actions from '../actions';

const initialState = {
    appTitleName: 'CoolR',
    loaderOpen: false,
    comboData: {},
    location: { data: null },
    asset: { data: null },
    hub: { data: null },
    vista: { data: null },
    hubStatus: { data: null, loading: false },
    vistaStatus: { data: null, loading: false },
    vistaPingStatus: { data: null, loading: false },
    locationCombo: { data: [], loading: false },
    assetCombo: { data: [], loading: false },
    vistaCombo: { data: [], loading: false },
    hubCombo: { data: [], loading: false },
    hubValue: '',
    assetValue: '',
    locationValue: '',
    currentLocation: null,
    userData: null,
    isSync: false,
    isCommandUpdated: false,
    factoryInstallUpdate: null,
    syncUpdate: null,
    commandStatus: null,
    commandIds: null,
    commandResult: null
};

const assignState = (state, propName, action) => {
    const { type, ...data } = action;
    return { ...state, [propName]: Object.assign({}, state[propName], data) };
}

const assignStateValue = (state, action) => {
    return { ...state, [action.type]: action[action.type] };
}


const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_LOCATION_COMBO:
            return assignState(state, 'locationCombo', action);
        case actions.SET_ASSET_COMBO:
            return assignState(state, 'assetCombo', action);
        case actions.SET_HUB_VALUE:
            return { ...state, hubValue: action.hubValue };
        case actions.SET_HUB_STATUS_DATA:
            return assignState(state, 'hubStatus', action);
        case actions.SET_VISTA_VALUE:
            return { ...state, vistaValue: action.vistaValue };
        case actions.SET_VISTA_STATUS_DATA:
            return assignState(state, 'vistaStatus', action);
        case actions.SET_LOCATION_VALUE:
            return { ...state, locationValue: action.locationValue };
        case actions.SET_ASSET_VALUE:
            return { ...state, assetValue: action.assetValue };
        case actions.SET_ASSET_TYPE_VALUE:
            return { ...state, assetTypeValue: action.assetTypeValue };
        case actions.SET_ASSET_SERIAL_NUMBER:
            return { ...state, assetSerialNumber: action.assetSerialNumber };
        case actions.SET_VISTA_PING_STATUS_DATA:
            return assignState(state, 'vistaPingStatus', action);
        case actions.SET_HUB_COMBO:
            return assignState(state, 'hubCombo', action);
        case actions.SET_VISTA_COMBO:
            return assignState(state, 'vistaCombo', action);
        case actions.SET_LOCATION:
        case actions.SET_ASSET:
        case actions.SET_CURRENT_LOCATION:
        case actions.SET_USER_DATA:
        case actions.SET_HUB:
        case actions.SET_VISTA:
        case actions.SET_FACTORY_INSTALL_UPDATE:
        case actions.SET_SYNC_UPDATE:
        case actions.SET_COMMAND_STATUS:
        case actions.SET_SYNC_COMMAND_STATUS:
        case actions.SET_SAVE_NOTE_STATUS:
            console.log(action);
            return assignState(state, action.type, action);
        case actions.SET_IS_SYNC:
        case actions.SET_IS_COMMAND_UPDATED:
        case actions.SET_COMMAND_IDS:
        case actions.SET_COMMAND_RESULT:
        case actions.SET_SYNC_COMMAND_IDS:
        case actions.SET_NOTES_VALUE:
            return assignStateValue(state, action);
        default:
            return state;
    }
}

export default appReducer;


