import React from 'react';
import utils from '../utils'
import StatusList from './StatusList';

import { BatteryAlert, Link, LinkOff, Check, Cancel, SignalCellular4Bar, SignalCellular0Bar, LocationOn, LocationOff, BatteryCharging60 } from "@material-ui/icons";

//Threshold values
const hubRssiThreshold = 95;
const kilometerDistanceThreshold = 1000;
const milesDistanceThreshold = 1609;

export default React.memo(function HubStatus({ hubStatus, currentLocation }) {

    if (hubStatus.loading) {
        return (<div>Loading....</div>);
    }
    if (hubStatus.error) {
        return (<div>Verify IMEI or MAC...</div>);
    }
    const hubStatusData = hubStatus.data;

    if (!hubStatusData) {
        return (<div>Select a hub to verify status</div>);
    }

    let {
        Category,
        LastPing,
        LatestLatitude,
        LatestLongitude,
        LatestRssi,
        IsPowerOn,
        MQTTStatus,
        HubBatteryLevel,
        ChargingStatus,
        TimeZoneAbbreviation,
        StatusId
    } = hubStatusData;

    const hasPosition = LatestLatitude && LatestLongitude;
    const distance = hasPosition ? utils.getDistance({ latitude: LatestLatitude, longitude: LatestLongitude }, currentLocation) : null;

    const lastPing = LastPing ? utils.DateTimeWithoutTimezone(LastPing) + ' ' + (TimeZoneAbbreviation || "") : '';
    const lastPingText = lastPing ? (" at " + lastPing) : "";

    const listItems = [
        {
            primary: "Sync",
            secondary: lastPing,
            goodIcon: <Check />,
            badIcon: <Cancel />,
            isGood: LastPing && utils.date_Diff_In_Mins(new Date(), utils.DateTimeWithoutTimezone(LastPing)) < 10,
            action: 'Check connection status. If device is not getting connected, you may need to move to another location'
        },
        {
            primary: "Connected: " + MQTTStatus,
            secondary: "",
            goodIcon: <Link />,
            badIcon: <LinkOff />,
            isGood: MQTTStatus === "Yes",
            action: 'Try to move to a location where device may connect to the cloud'
        },
        {
            primary: LatestRssi ? Math.abs(LatestRssi) : '',
            secondary: Category ? utils.getNetworkValue(Category) : "N/A",
            goodIcon: <SignalCellular4Bar />,
            badIcon: <SignalCellular0Bar />,
            isGood: LatestRssi && Math.abs(LatestRssi) < hubRssiThreshold,
            action: 'Try to move to a location where you can get a better signal'
        },
        {
            primary: (IsPowerOn ? "On" : "Off") + " " + (IsPowerOn ? (ChargingStatus ? "Charging" : "Battery") : ''),
            secondary: HubBatteryLevel + "%" + lastPingText,
            goodIcon: <BatteryCharging60 />,
            badIcon: <BatteryAlert />,
            isGood: IsPowerOn && ChargingStatus,
            action: 'Make sure power is connected and you have at-least 5% charge before performing actions. Otherwise, actions may fail'
        },
        {
            primary: hasPosition ? (distance === null ? "Can't determine distance" : (distance < kilometerDistanceThreshold ? "Nearby" : "Far")) : "Can't get position",
            secondary: hasPosition ? <span>{LatestLatitude}/ {LatestLongitude} ({!!distance && <span>{Math.round(distance / kilometerDistanceThreshold)} Km. / {Math.round(distance / milesDistanceThreshold)} Mi</span>})</span> : "-",
            goodIcon: <LocationOn />,
            badIcon: <LocationOff />,
            isGood: hasPosition && distance !== null && distance < kilometerDistanceThreshold,
            action: 'Make sure the hub IMEI is correct and is nearby. Sometimes GPS may be inaccurate'
        }
    ];

    return <StatusList listItems={listItems} />
});