import React from 'react';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import './App.css';
import FactoryInstall from './components/FactoryInstall';

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <header>
          <FactoryInstall />
        </header>
      </div>
    </Provider>
  );
}

export default App;
