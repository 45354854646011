
let url = (window.location.host.indexOf("localhost") !== -1) ? 'http://localhost/CoolR-Portal/' : 'https://portal.coolrgroup.com/';
let urlWithControllers = url + "Controllers/"
const apis = {
    url: url,
    Login: urlWithControllers + 'Login.ashx',
    Combo: urlWithControllers + 'Combo.ashx',
    SmartDevice: urlWithControllers + 'SmartDevice.ashx',
    Location: urlWithControllers + 'Location.ashx',
    Asset: urlWithControllers + 'Asset.ashx',
    SmartDeviceping: urlWithControllers + 'SmartDeviceping.ashx',
    Clientlogo: url + 'FileServer/ClientIogo/'
}

export default apis;