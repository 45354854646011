const actions = {
  SET_USER_DATA: 'user',
  SET_LOCATION_COMBO: 'SET_LOCATION_COMBO',
  SET_ASSET_COMBO: 'SET_ASSET_COMBO',
  SET_HUB_VALUE: 'SET_HUB_VALUE',
  SET_HUB_STATUS_DATA: 'SET_HUB_STATUS_DATA',
  SET_VISTA_VALUE: 'SET_VISTA_VALUE',
  SET_VISTA_STATUS_DATA: 'SET_VISTA_STATUS_DATA',
  SET_LOCATION_VALUE: 'SET_LOCATION_VALUE',
  SET_ASSET_VALUE: 'SET_ASSET_VALUE',
  SET_ASSET_TYPE_VALUE: 'SET_ASSET_TYPE_VALUE',
  SET_ASSET_SERIAL_NUMBER: 'SET_ASSET_SERIAL_NUMBER',
  SET_VISTA_PING_STATUS_DATA: 'SET_VISTA_PING_STATUS_DATA',
  SET_HUB_COMBO: 'SET_HUB_COMBO',
  SET_VISTA_COMBO: 'SET_VISTA_COMBO',
  SET_LOCATION: 'location',
  SET_ASSET: 'asset',
  SET_HUB: 'hub',
  SET_VISTA: 'vista',
  SET_CURRENT_LOCATION: 'currentLocation',
  SET_IS_SYNC: 'isSync',
  SET_IS_COMMAND_UPDATED: 'isCommandUpdated',
  SET_FACTORY_INSTALL_UPDATE: 'factoryInstallUpdate',
  SET_SYNC_UPDATE: 'syncUpdate',
  SET_COMMAND_STATUS: 'commandStatus',
  SET_COMMAND_IDS: 'commandIds',
  SET_COMMAND_RESULT: 'commandResult',
  SET_SYNC_COMMAND_STATUS: 'commandSyncStatus',
  SET_SYNC_COMMAND_IDS: 'commandSyncIds',
  SET_SAVE_NOTE_STATUS: 'noteStatus',
  SET_NOTES_VALUE: 'notes'
};

export default actions;