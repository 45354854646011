import React, { useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button, Box, CircularProgress, Stepper, Step, StepLabel, StepContent, ListItemAvatar, Avatar, ListItem, ListItemText } from '@material-ui/core';
import { Autocomplete, Alert } from '@material-ui/lab';
import * as colors from "@material-ui/core/colors";
import LocationOn from "@material-ui/icons/LocationOn";
import CoolerIcon from '@material-ui/icons/Kitchen';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import swal from '@sweetalert/with-react';
import { getHubSuggestions, getVistaStatus, getLocationComboData, getAssetComboData, login, factoryInstallation, sync, getCommandStatus, getSyncCommandStatus, saveNotes } from "../httpUtils/httpRequest";
import apis from "../httpUtils/apis";
import actions from '../redux/actions';
import HubStatus from './HubStatus';
import VistaStatus from './VistaStatus';
import utils from '../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 500
    },
    green: {
        color: theme.palette.getContrastText(colors.green[500]),
        backgroundColor: colors.green[500]
    },
    red: {
        color: theme.palette.getContrastText(colors.red[900]),
        backgroundColor: colors.red[900]
    },
    subTitle: {
        color: theme.palette.getContrastText(colors.blue[500]),
        backgroundColor: colors.blue[500],
        textAlign: "center"
    },
    buttonContainer: {
        "& > *": {
            margin: theme.spacing(1)
        }
    },
    textAlignment: {
        textAlign: 'center'
    }
}));
let dispatch, requestCount = 0, syncRequestCount = 0;
const emptyObject = {}, emptyString = '';
const FactoryInstall = React.memo(function FactoryInstall() {
    const classes = useStyles();
    dispatch = useDispatch();
    let clientId, clientName = "";
    let userData = useSelector(state => state.appReducer.user);
    let locationComboOptions = useSelector(state => state.appReducer.locationCombo);
    let assetComboOptions = useSelector(state => state.appReducer.assetCombo);
    let vistaComboOptions = useSelector(state => state.appReducer.vistaCombo);
    let hubComboOptions = useSelector(state => state.appReducer.hubCombo);
    let vistaValue = useSelector(state => state.appReducer.vistaValue);
    let hubValue = useSelector(state => state.appReducer.hubValue);
    let locationValue = useSelector(state => state.appReducer.locationValue);
    let assetValue = useSelector(state => state.appReducer.assetValue);
    let vistaStatus = useSelector(state => state.appReducer.vistaStatus);
    let vistaPing = useSelector(state => state.appReducer.vistaPingStatus);
    let hubStatus = useSelector(state => state.appReducer.hubStatus);
    let location = useSelector(state => state.appReducer.location.data);
    let asset = useSelector(state => state.appReducer.asset.data);
    let hub = useSelector(state => state.appReducer.hub.data);
    let vista = useSelector(state => state.appReducer.vista.data);
    let currentLocation = useSelector(state => state.appReducer.currentLocation || emptyObject);

    //getting Response while Install device and Sync
    let factoryInstallUpdate = useSelector(state => state.appReducer.factoryInstallUpdate);
    let syncUpdate = useSelector(state => state.appReducer.syncUpdate);
    let commandStatus = useSelector(state => state.appReducer.commandStatus);
    let commandSyncStatus = useSelector(state => state.appReducer.commandSyncStatus);
    const commandSyncIds = useSelector(state => state.appReducer.commandSyncIds);

    //Commands IDs and Result
    const commandIds = useSelector(state => state.appReducer.commandIds);
    const commandResult = useSelector(state => state.appReducer.commandResult);

    //Notes
    const notes = useSelector(state => state.appReducer.notes);

    //show loader while sync and command update
    const isSync = useSelector(state => state.appReducer.isSync);
    const isCommandUpdated = useSelector(state => state.appReducer.isCommandUpdated);

    //debounce 
    const [hubSearchDebounce] = useDebounce(hubValue, 300);
    const [vistaSearchDebounce] = useDebounce(vistaValue, 300);
    const [locationSearchDebounce] = useDebounce(locationValue, 300);
    const [assetSearchDebounce] = useDebounce(assetValue, 300);

    const installDevice = () => {
        dispatch(factoryInstallation({
            action: 'factoryInstallation',
            AssetSerialNumber: asset && asset.SerialNumber,
            VistaSerialNumber: vistaValue,
            HubSerialNumber: hub && hub.Imei || hub && hub.MacAddress || hubValue,
            IsCreateAssetCheck: false,
            IsAddInstallCommand: true,
            ClientId: clientId,
            HubLocation: notes || emptyString
        }));
    };

    const onCommandCancel = () => {
        dispatch({ type: actions.SET_IS_COMMAND_UPDATED, isCommandUpdated: false });
    }

    useEffect(() => {
        if (commandStatus && commandStatus.data && !commandStatus.loading) {
            let obj = JSON.parse(commandStatus.data);
            commandResponseHandler(obj);
        }
    }, [commandStatus]);

    const commandResponseHandler = (obj) => {
        let remainingIds = '';
        obj.forEach((o) => {
            let isMinValue = utils.checkMinDate(o.ExecutedOn);
            if (!o.IsSuccess && isMinValue) {
                remainingIds += remainingIds === "" ? o.SmartDeviceCommandId : ',' + o.SmartDeviceCommandId;
            }
        });
        dispatch({ type: actions.SET_COMMAND_RESULT, commandResult: obj });
        if (!isCommandUpdated)
            return;
        if (remainingIds.length > 0 && requestCount < 5) {
            requestCount++;
            setTimeout(() => {
                dispatch(getCommandStatus(commandIds));
            }, 5000);
            return;
        }
        dispatch({ type: actions.SET_IS_COMMAND_UPDATED, isCommandUpdated: false });
        if (remainingIds.length === 0) {
            dispatch(getVistaStatus(vistaValue));
            dispatch(getHubSuggestions(hubValue));
            return;
        }
        swal({
            title: "Command not executed yet, get command status again?", icon: "info", buttons: ["Cancel", "Retry"], dangerMode: false,
        }).then((isConfirm) => {
            if (isConfirm) {
                requestCount = 0;
                dispatch({ type: actions.SET_IS_COMMAND_UPDATED, isCommandUpdated: true });
                dispatch(getCommandStatus(commandIds));
            }
        });
    };

    useEffect(() => {
        if (factoryInstallUpdate && factoryInstallUpdate.data && !factoryInstallUpdate.loading) {
            let obj = {};
            if (utils.IsValidJSONString(factoryInstallUpdate.data)) {
                obj = JSON.parse(factoryInstallUpdate.data)
            } else {
                obj['UpdateMsg'] = factoryInstallUpdate.data;
            }
            responseHandler(obj);
        }
    }, [factoryInstallUpdate]);

    const responseHandler = (obj) => {
        switch (obj.UpdateMsg) {
            case 'Smart Device is already provisioned.':
                swal({
                    title: "Device is already provisioned. Do you want to uninstall and install it again?", icon: "info", buttons: true, dangerMode: true,
                }).then((isConfirm) => {
                    if (isConfirm) {
                        dispatch(factoryInstallation({ action: 'unInstallSmartDevice', deviceSerialNumber: vistaValue, includeClientIdZero: true }));
                    }
                });
                break;
            case 'Device uninstalled successfully':
                installDevice();
                break;
            case 'Device provisioned successfully':
                if (obj.HubUpdate !== "Hub not found" && obj.VistaStatus !== 'Smart Device does not exist') {
                    swal({
                        title: "Provisioned Successfully", icon: "success", buttons: ["cancel", "Next"], dangerMode: false,
                    }).then((isConfirm) => {
                        if (isConfirm) {
                            dispatch({ type: actions.SET_COMMAND_RESULT, commandResult: [] });
                            requestCount = 0;
                            dispatch({ type: actions.SET_COMMAND_IDS, commandIds: obj.commandIds });
                            dispatch(getCommandStatus(obj.commandIds));
                            dispatch({ type: actions.SET_IS_COMMAND_UPDATED, isCommandUpdated: true });
                        }
                    });
                } else {
                    swal(
                        <div style={{ textAlign: 'justify' }}>
                            <h3 style={{ textAlign: 'center' }}>Status</h3>
                            <p><b>Hub:</b> {obj.HubUpdate}</p>
                            <p><b>Vista:</b> {obj.VistaUpdate}</p>
                            <p><b>Asset:</b> {obj.AssetUpdate}</p>
                        </div>
                    )
                }
                break;
            default:
                swal(
                    <div style={{ textAlign: 'justify' }}>
                        <h3 style={{ textAlign: 'center' }}>Status</h3>
                        <p><b>Hub:</b> {obj.HubUpdate}</p>
                        <p><b>Asset:</b> {obj.AssetUpdate}</p>
                    </div>
                )
        }
    };

    const onPosition = (position) => {
        console.log(position);
        const coords = position.coords;
        dispatch({ type: actions.SET_CURRENT_LOCATION, latitude: coords.latitude, longitude: coords.longitude });
    }

    const onPositionError = (err) => {
        console.log("Couldn't get position");
        dispatch({ type: actions.SET_CURRENT_LOCATION, latitude: null, longitude: null });
    }

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(onPosition, onPositionError, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        });

        const params = utils.searchToObject();
        const token = params.token;
        if (token) {
            dispatch(login(token));
        } else {
            dispatch({
                type: actions.SET_USER_DATA,
                data: {
                    success: false
                }
            });
        }
    }, []);

    useEffect(() => {
        if (syncUpdate && syncUpdate.data && !syncUpdate.loading) {
            if (utils.IsValidJSONString(syncUpdate.data)) {
                onSyncUpdateHandler(JSON.parse(syncUpdate.data))
            }
        }
    }, [syncUpdate]);

    const onSync = () => { dispatch(sync(hubValue)); };

    const onSyncCancel = () => {
        dispatch({ type: actions.SET_IS_SYNC, isSync: false });
    }

    const onSyncUpdateHandler = (obj) => {
        dispatch({ type: actions.SET_IS_SYNC, isSync: true });
        dispatch({ type: actions.SET_COMMAND_IDS, commandIds: obj.CommandIds });
        if (obj.Message == "Hub not found!") {
            dispatch({ type: actions.SET_IS_SYNC, isSync: false });
            swal({ title: obj.Message, icon: "info", dangerMode: false });
        } else {
            syncRequestCount = 0;
            dispatch({ type: actions.SET_SYNC_COMMAND_IDS, commandSyncIds: obj.CommandIds });
            dispatch(getSyncCommandStatus(obj.CommandIds));
        }
    };

    useEffect(() => {
        if (commandSyncStatus && commandSyncStatus.data && !commandSyncStatus.loading) {
            commandSyncResponseHandler(JSON.parse(commandSyncStatus.data));
        }
    }, [commandSyncStatus]);

    const commandSyncResponseHandler = (obj) => {
        let remainingIds = '';
        obj.forEach((o) => {
            let isMinValue = utils.checkMinDate(o.ExecutedOn);
            if (!o.IsSuccess && isMinValue) {
                remainingIds += remainingIds === "" ? o.SmartDeviceCommandId : ',' + o.SmartDeviceCommandId;
            }
        });
        if (!isSync)
            return;
        if (remainingIds.length > 0 && syncRequestCount < 5) {
            syncRequestCount++;
            setTimeout(() => {
                dispatch(getSyncCommandStatus(commandSyncIds));
            }, 3000);
            return;
        }
        dispatch({ type: actions.SET_IS_SYNC, isSync: false });
        if (remainingIds.length === 0) {
            dispatch(getVistaStatus(vistaValue));
            dispatch(getHubSuggestions(hubValue));
            return;
        }
        swal({
            title: "Sync commands not executed yet, check again?", icon: "info", buttons: ["Cancel", "Retry"], dangerMode: false,
        }).then((isConfirm) => {
            if (isConfirm) {
                syncRequestCount = 0;
                dispatch({ type: actions.SET_IS_SYNC, isSync: true });
                dispatch(getSyncCommandStatus(commandSyncIds));
            }
        });
    };

    const setNoteFun = (event) => {
        const { value = '' } = event.target;
        dispatch({ type: actions.SET_NOTES_VALUE, notes: value });
    }

    const onSaveNotes = () => {
        dispatch(saveNotes(hubValue, notes));
    }

    useEffect(() => {
        dispatch(getLocationComboData(locationSearchDebounce));
    }, []);

    useEffect(() => {
        dispatch(getLocationComboData(locationSearchDebounce));
    }, [locationSearchDebounce]);

    useEffect(() => {
        dispatch(getAssetComboData(assetSearchDebounce));
    }, []);

    useEffect(() => {
        dispatch(getAssetComboData(assetSearchDebounce, location));
    }, [location, assetSearchDebounce]);
    useEffect(() => {
        if (hubSearchDebounce) {
            dispatch(getHubSuggestions(hubSearchDebounce));
        }
    }, [hubSearchDebounce]);

    useEffect(() => {
        if (vistaSearchDebounce) {
            dispatch(getVistaStatus(vistaSearchDebounce));
        }
    }, [vistaSearchDebounce]);

    useEffect(() => {
        if (hubStatus.data) {
            dispatch({ type: actions.SET_NOTES_VALUE, notes: hubStatus.data.HubLocation });
        }
    }, [hubStatus.data]);

    const firstHubOption = hubComboOptions.data[0] || emptyObject;
    const hubComboOpen = hubComboOptions.data.length > 1 || (firstHubOption.Imei !== hubValue && firstHubOption.MacAddress !== hubValue);

    if (userData == null) {
        return <div>Loading...</div>
    }

    if (userData.loading) {
        return <div>Logging in...</div>;
    }

    if (!userData.data.success) {
        return <div>Login failed</div>;
    }

    userData = userData.data;
    clientId = userData.tags.ClientId;
    clientName = userData.tags.ClientName;

    const canInstall = asset && asset.SerialNumber && vistaStatus.data && vistaStatus.data.LastPing && hubStatus.data && hubStatus.data.LastPing;
    const canSaveNotes = hubValue && notes;

    return (
        <div className={classes.root}>
            <Typography className={classes.textAlignment} component="h1" variant="h5">
                CoolR Installer
              </Typography>
            <div className="client-app-bar">
                <Box className="logo-image-container"><img src={`${apis.Clientlogo + clientId}.png`} className="logo-image" alt="Client Logo" onError={(e) => e.target.src = "https://portal.coolrgroup.com/images/portal/toplogo.png"} /></Box>
                <Box >{clientName}</Box>
            </div>
            <Stepper orientation="vertical">
                <Step key="1">
                    <StepLabel>Hub</StepLabel>
                    <StepContent>
                        <Autocomplete
                            //open={hubComboOpen}
                            autoSelect={(hubComboOptions.data.length === 1 && hubValue) ? true : false}
                            autoHighlight
                            getOptionSelected={(option, value) => option.Imei === value.Imei}
                            options={hubComboOptions.data}
                            getOptionLabel={option => option.DisplayValue}
                            renderOption={(option) => {
                                return (<div>{option.Imei}<div>{"(" + option.MacAddress + ")"}</div></div>)
                            }}
                            autoComplete
                            includeInputInList
                            inputValue={hubValue}
                            onChange={(event, value, reason) => {
                                dispatch({ type: actions.SET_HUB, data: value });
                            }}
                            onInputChange={(event, newInputValue) => {
                                let hubRex = new RegExp(hubValue, "gi");
                                let rex = /^[\w:]*$/;         //Validating Alphabets, Numbers and colon 
                                let macValue = newInputValue.substring(newInputValue.lastIndexOf("(") + 1, newInputValue.lastIndexOf(")"));
                                let imeiValue = newInputValue.substr(0, newInputValue.indexOf('('));
                                if (hubValue) {
                                    newInputValue = newInputValue.toString().slice(0, 17);
                                    if (macValue && macValue.match(hubRex) && macValue.match(hubRex)[0]) {
                                        newInputValue = macValue
                                    }
                                    else if (imeiValue && imeiValue.match(hubRex) && imeiValue.match(hubRex)[0]) {
                                        newInputValue = imeiValue
                                    }
                                }
                                if (!rex.test(newInputValue)) {
                                    newInputValue = "";
                                }
                                dispatch({ type: 'SET_HUB_VALUE', hubValue: newInputValue });
                            }}
                            renderInput={(params) => <TextField error={utils.isObjectEmpty(hubStatus) && hubSearchDebounce} {...params} helperText={utils.isObjectEmpty(hubStatus) && hubSearchDebounce ? "Hub not found" : ""} label="Hub MAC or IMEI"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {hubComboOptions.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                    maxLength: 17
                                }}
                            />}
                        />
                        <Button variant="contained" color="primary" onClick={!isSync ? onSync : onSyncCancel} disabled={utils.isObjectEmpty(hubStatus)}>{!isSync ? 'Sync' : 'Cancel'}</Button>
                        {isSync ? <> <CircularProgress color="inherit" size={20} /> Syncing...</> : null}
                        <HubStatus hubStatus={hubStatus} currentLocation={currentLocation} />
                    </StepContent>
                </Step>
                <Step key="2" expanded="true">
                    <StepLabel>Vista</StepLabel>
                    <StepContent>
                        <Autocomplete
                            getOptionSelected={(option, value) => option.name === value.name}
                            options={vistaComboOptions.data}
                            getOptionLabel={option => option.SerialNumber}
                            id="vista"
                            autoComplete
                            includeInputInList
                            inputValue={vistaValue || emptyString}
                            freeSolo={true}
                            onChange={(event, value, reason) => {
                                dispatch({ type: actions.SET_VISTA, data: value });
                            }}
                            onInputChange={(event, newInputValue) => {
                                dispatch({ type: 'SET_VISTA_VALUE', vistaValue: newInputValue });
                            }}
                            renderInput={(params) => <TextField  {...params} label="Vista Serial #" type="number"
                                InputProps={{ ...params.InputProps }}

                            />}
                        />
                        <VistaStatus vistaStatus={vistaStatus} vistaPingStatus={vistaPing} />
                    </StepContent>
                </Step>
                <Step key="3" expanded="true">
                    <StepLabel>Location (Outlet/ Store)</StepLabel>
                    <StepContent>
                        <Autocomplete
                            getOptionSelected={(option, value) => option.name === value.name}
                            options={locationComboOptions.data}
                            getOptionLabel={option => option.Name}
                            id="outlet"
                            autoComplete
                            includeInputInList
                            inputValue={locationValue || emptyString}
                            onChange={(event, value, reason) => {
                                dispatch({ type: actions.SET_LOCATION, data: value });
                            }}
                            onInputChange={(event, newInputValue) => {
                                dispatch({ type: actions.SET_LOCATION_VALUE, locationValue: newInputValue });
                            }}
                            renderInput={(params) => <TextField {...params} label="Outlet Name"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {locationComboOptions.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />}
                        />
                        {location ? <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.green}>
                                    <LocationOn />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={location.Name}
                                secondary={`${location.Street} ${location.City} ${!!location.State ? location.State : ''} ${location.PostalCode}`}
                            />
                        </ListItem> : <div>Select a location</div>}
                    </StepContent>
                </Step>
                <Step key="4" expanded="true">
                    <StepLabel>Asset (Cooler/ Wall)</StepLabel>
                    <StepContent>
                        <Autocomplete
                            className={classes.spacing}
                            getOptionSelected={(option, value) => option.name === value.name}
                            options={assetComboOptions.data}
                            getOptionLabel={option => option.SerialNumber}
                            id="asset"
                            autoComplete
                            includeInputInList
                            onChange={(event, value, reason) => {
                                dispatch({ type: actions.SET_ASSET, data: value });
                                if (value) {
                                    let { SmartDeviceSerialNumber, MDMImeiNumber, MdMDeviceMacAddress, Location, LocationId } = value;
                                    if (MDMImeiNumber || MdMDeviceMacAddress) {
                                        dispatch({ type: actions.SET_HUB_VALUE, hubValue: MDMImeiNumber || MdMDeviceMacAddress });
                                    }
                                    if (SmartDeviceSerialNumber) {
                                        dispatch({ type: actions.SET_VISTA_VALUE, vistaValue: SmartDeviceSerialNumber });
                                    }
                                    if (LocationId && (!location || location.LocationId !== LocationId)) {
                                        dispatch({ type: actions.SET_LOCATION_VALUE, locationValue: Location });
                                    }
                                }
                            }}
                            onInputChange={(event, newInputValue) => {
                                dispatch({ type: actions.SET_ASSET_VALUE, assetValue: newInputValue });
                            }}
                            renderInput={(params) => <TextField {...params} label="Asset Serial #"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {assetComboOptions.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }} />}
                        />
                        {asset ? <ListItem>
                            <ListItemAvatar>
                                <Avatar className={classes.green}>
                                    <CoolerIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${asset.SerialNumber}`}
                                secondary={asset.AssetType} />
                        </ListItem> : 'Select an asset'}
                    </StepContent>
                </Step>
                <Step key="5" expanded="true">
                    <StepLabel>Install/ Notes</StepLabel>
                    <StepContent>
                        <TextField label="Notes" value={notes || ''} onChange={setNoteFun} />
                        <div className={classes.buttonContainer}>
                            <Button variant="contained" color={"primary"} disabled={!canSaveNotes} onClick={canSaveNotes ? onSaveNotes : null}>Save Notes</Button>
                            <Button variant="contained" color={"primary"} disabled={!canInstall} onClick={canInstall ? installDevice : null}>Save & Install</Button>
                        </div>
                    </StepContent>
                </Step>
                <Step key="6" expanded="false">
                    <StepLabel>Install Summary {isCommandUpdated ? <span><CircularProgress color="inherit" size={20} /> <Button style={{ float: 'right' }} variant="outlined" color="secondary" onClick={onCommandCancel}>Cancel</Button> </span> : null}</StepLabel>
                    <StepContent>
                        {commandResult && commandResult.map((val, index) => {
                            let type = val.Result ? (val.IsSuccess ? 'success' : 'error') : 'warning';
                            return (
                                <Alert className={classes.alertText} severity={type}>{val.CommandName} - {val.Result ? (val.IsSuccess ? 'Success' : 'Failed') : 'Waiting...'}</Alert>
                            )
                        })}
                    </StepContent>
                </Step>
            </Stepper>

        </div>
    );
})

export default FactoryInstall;