import moment from 'moment';

const geolib = require('geolib');
export default {
    IsValidJSONString: function (str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    },

    DateTimeWithoutTimezone: function (value) {
        var date = value instanceof Date ? value : this.parse(value);
        date = date ? moment(date).format('MM/DD/YYYY hh:mm:ss A') : '';
        return date;
    },

    parse: function (value) {
        if (!value) {
            return null;
        }
        value = value.toString();

        if (value.indexOf('-') !== -1) {
            return new Date(value)
        }

        return new Date(Number(value.substr(0, 4)),
            Number(value.substr(4, 2)) - 1,
            Number(value.substr(6, 2)),
            Number(value.substr(8, 2)),
            Number(value.substr(10, 2)),
            Number(value.substr(12, 2))
        )
    },

    checkMinDate(d) {
        if (!d) {
            return true
        }
        let minDate = moment.utc("0001-01-01"); // minimum value as per UTC
        let receiveDate = moment(d); 
         return moment.utc(receiveDate).startOf('day').isSame(moment(minDate).startOf('day'));
    },

    date_Diff_In_Mins(date1, date2) {
        if (!date1 && !date2) {
            return null;
        }
        var a = moment(date1);
        var b = moment(date2);
        return a.diff(b, 'minutes');
    },

    getVoltage(value) {
        let batteryLevel = Number(value) / 1000;
        return batteryLevel.toFixed(2).toString() + "V";
    },

    getDistance(pos1, pos2) {
        if (pos2.latitude && pos2.longitude) {
            return geolib.getPreciseDistance(pos1, pos2);
        }
    },
    getNetworkValue(category) {
        var network = 'N/A';
        if (category.indexOf('WiFi On: True') > -1) {
            network = 'WiFi';
        }
        if (category.indexOf('Cellular: True') > -1) {
            network = 'Cellular';
        }
        return network;
    },

    isObjectEmpty: function (value) {
        return (
            Object.prototype.toString.call(value) === '[object Object]' &&
            JSON.stringify(value) === '{}'
        );
    },

    searchToObject: function () {
        var pairs = window.location.search.substring(1).split("&"),
            obj = {},
            pair,
            i;

        for (i in pairs) {
            if (pairs[i] === "") continue;

            pair = pairs[i].split("=");
            obj[decodeURIComponent(pair[0]).toLocaleLowerCase()] = decodeURIComponent(pair[1]);
        }

        return obj;
    }
}