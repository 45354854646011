import React from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import actions from '../redux/actions';
import apis from './apis';

const getFormData = (props) => {
    let formData = new FormData();
    for (let key in props) {
        formData.append(key, props[key]);
    }
    return formData;
}

const defaultParser = (result) => {
    return result.data.records;
};

const transport = axios.create({ withCredentials: true });

const ajax = async ({ dispatch, action, url, params, resetData = false, parser = defaultParser }) => {
    const dispatchInfo = { type: action, error: null, loading: true };
    if (resetData) {
        dispatchInfo.data = resetData === true ? null : resetData
    };
    // set loading
    dispatch(dispatchInfo);
    const formData = new FormData();
    for (const key in params) {
        formData.set(key, params[key]);
    }
    let reqParams = {
        method: 'POST',
        credentials: 'include',
        url: url,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData
    };
    try {
        const result = await transport(reqParams);
        dispatchInfo.data = parser(result.data);
        dispatchInfo.loading = false;
        dispatch(dispatchInfo);
    } catch (err) {
        console.log(err);
        dispatchInfo.error = err;
        dispatchInfo.loading = false;
        dispatch(dispatchInfo);
    }
    return dispatchInfo;
}

export const getHubSuggestions = (serial) => async dispatch => {
    const suggestions = await ajax({
        dispatch,
        action: actions.SET_HUB_COMBO,
        url: apis.SmartDevice,
        params: { action: 'getHubSuggestions', serial: serial },
    });
    if (suggestions.error || suggestions.data.length === 0) {
        return;
    }
    const record = suggestions.data[0];
    if (record.Imei !== serial && record.MacAddress !== serial) {
        return;
    }
    // Load hub status
    ajax({
        dispatch,
        action: actions.SET_HUB_STATUS_DATA,
        url: apis.SmartDevice,
        params: { action: 'getSmartDeviceInfo', serial: serial },
        resetData: true,
        parser: (result) => result.data.records[0]
    });
    // Try to load vista combo
    ajax({
        dispatch,
        action: actions.SET_VISTA_COMBO,
        url: apis.SmartDevice,
        params: { action: 'getDeviceFromPing', serial: serial },
        resetData: []
    });
};

export const getVistaStatus = (serial) => async dispatch => {
    if (serial.length < 5) {
        return;
    }
    const deviceInfo = await ajax({
        dispatch,
        action: actions.SET_VISTA_STATUS_DATA,
        url: apis.SmartDevice,
        params: { action: 'getSmartDeviceInfo', serial: serial },
        parser: (result) => result.data.records[0]
    });
    if (deviceInfo.error || !deviceInfo.data) {
        return;
    }
    ajax({
        dispatch,
        action: actions.SET_VISTA_PING_STATUS_DATA,
        url: apis.SmartDeviceping,
        params: {
            action: 'list',
            start: 0,
            limit: 1,
            'filter[0][field]': 'DeviceSerial',
            'filter[0][data][type]': 'string',
            'filter[0][data][value]': serial,
            asArray: 0,
            sort: 'CreatedOn',
            dir: 'DESC'
        },
        resetData: true,
        parser: (result) => result.records[0]
    });
};

export const getLocationComboData = (name) => async dispatch => {
    ajax({
        dispatch,
        action: actions.SET_LOCATION_COMBO,
        url: apis.Location,
        params: {
            action: 'list',
            asArray: 0,
            start: 0,
            limit: 50,
            locationName: name,
            sort: 'Name',
            dir: 'ASC'
        },
        parser: (result) => result.records
    });
};

export const getAssetComboData = (search, location) => async dispatch => {
    // todo: search based on location if chosen
    ajax({
        dispatch,
        action: actions.SET_ASSET_COMBO,
        url: apis.Asset,
        params: {
            action: 'list',
            asArray: 0,
            start: 0,
            limit: 50,
            serialNumber: search,
            locationId: location ? location.LocationId : null,
            sort: 'SerialNumber',
            dir: 'ASC'
        },
        parser: (result) => result.records
    });
};

export const factoryInstallation = (params) => async dispatch => {
    ajax({
        dispatch,
        action: actions.SET_FACTORY_INSTALL_UPDATE,
        url: apis.SmartDevice,
        params: params,
        parser: (result) => result.data
    });
};

export const sync = (value) => async dispatch => {
    ajax({
        dispatch,
        action: actions.SET_SYNC_UPDATE,
        url: apis.Asset,
        params: {
            action: "setStartVHService",
            ImeiOrMac: value
        },
        parser: (result) => result.data
    });
};

export const getCommandStatus = (ids) => async dispatch => {
    ajax({
        dispatch,
        action: actions.SET_COMMAND_STATUS,
        url: apis.SmartDevice,
        params: {
            action: "getRemoteCommandResult",
            CommandIds: ids
        },
        parser: (result) => result.data
    });
};

export const getSyncCommandStatus = (ids) => async dispatch => {
    ajax({
        dispatch,
        action: actions.SET_SYNC_COMMAND_STATUS,
        url: apis.SmartDevice,
        params: {
            action: "getRemoteCommandResult",
            CommandIds: ids,
            isForVhCommand: true
        },
        parser: (result) => result.data
    });
};

export const saveNotes = (imei, notes) => async dispatch => {
    ajax({
        dispatch,
        action: actions.SET_SAVE_NOTE_STATUS,
        url: apis.SmartDevice,
        params: {
            action: "saveHubLocation",
            imeiOrMac: imei,
            HubLocation: notes
        },
        parser: (result) => result.data
    });
};

export const login = (token) => async dispatch => {
    ajax({
        dispatch,
        action: actions.SET_USER_DATA,
        url: apis.Login,
        params: {
            token: token,
            rememberMe: true
        },
        parser: (result) => result
    })
};