import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemAvatar, Avatar, List, ListItem, ListItemText, ListSubheader, ListItemSecondaryAction } from "@material-ui/core";
import * as colors from "@material-ui/core/colors";
import HelpOutline from "@material-ui/icons/HelpOutline";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
    green: {
        color: theme.palette.getContrastText(colors.green[500]),
        backgroundColor: colors.green[500]
    },
    red: {
        color: theme.palette.getContrastText(colors.red[900]),
        backgroundColor: colors.red[900]
    },
    blue: {
        color: colors.blue[900]
    }
}));

export default React.memo(function StatusList({ listItems }) {
    const classes = useStyles();
    return (<List dense>
        {listItems.map((item, index) =>
            <ListItem key={index}>
                <ListItemAvatar>
                    <Avatar className={item.isGood ? classes.green : classes.red}>{item.isGood ? item.goodIcon : item.badIcon}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.primary} secondary={item.secondary}/>
                <ListItemSecondaryAction>
                    {!item.isGood && item.action && <Tooltip title={item.action} leaveDelay={2000}>
                        <HelpOutline className={classes.blue} />
                    </Tooltip>}
                </ListItemSecondaryAction>
            </ListItem>
        )}
    </List>
    )
});
